.login {    
    .login-header {
        h1 {
            font: normal normal 800 30px/41px Open Sans;
        }
    
        .sub-title {
            font: normal normal normal 16px/22px Open Sans;
            padding: 0 35px;
        }

        button {
            position: absolute;
            left: 45px;
            top: 42px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            &:hover{
                color: var(--secondary-color);
            }
        }
    }

    .info-phone {
        font: normal normal 300 16px/22px Open Sans;
        max-width: 100%;
        text-align: center;
        margin: 0 auto 50px;
    }

    .btn {
        font: normal normal 800 13px/18px Open Sans !important;
    }

    .link{
        color: var(--primary-color);
        font-weight: 600;
        cursor: pointer;
    }
}