html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  font-size: 15px;
  color: var(--text-color);
}

body,
input,
select,
option,
textarea,
button {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
}

iframe {
  display: none;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 13px;
  }
}

*,
*::after,
*::before {
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
}

img {
  display: block;
  max-width: 100%;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited:hover {
  color: var(--primary-color);
}

b,
strong,
.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 800;
}

.italic {
  font-style: italic !important;
}

h1 {
  font-size: 2em;
}

.big {
  font-size: 3.4em;
  line-height: 1.4em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  line-height: 1.2em;
}

h1.thin,
h2.thin,
h3.thin,
h4.thin,
h5.thin,
h6.thin {
  font-weight: 400;
}

h1.small {
  font-size: 1.6em;
}

p {
  line-height: 1.6em;
  color: var(--text-color);
}

p:not(:last-of-type) {
  margin-bottom: 20px;
}

hr {
  border-top: 1px solid var(--border-color-o3);
}

hr.line {
  border-top: 1px solid var(--border-color-o3);
}

.main-navigation hr {
  border-top: 1px solid #e2e2e2;
}

.input-group {
  position: relative;
}

label {
  font-size: 0.8em;
  padding: 0 5px;
  background-color: var(--white-color);
}

label.normal {
  font-size: 1em;
}

.input-group label {
  position: absolute;
  top: -7px;
  left: 35px;
  z-index: 1;
}

input:not([type="range"]),
select {
  width: 100%;
  height: 65px;
  border: 1px solid var(--border-color-o3);
  border-radius: 24px;
  padding: 0 20px;
  transition: var(--transition);
}

textarea {
  width: 100%;
  border: 1px solid var(--border-color-o3);
  border-radius: 24px;
  padding: 10px 20px;
  transition: var(--transition);
  resize: none;
}

select {
  height: 50px;
  appearance: none;
  -webkit-appearance: none;
  padding-left: 25px;
  padding-right: 25px;
  background-color: transparent;
  font-weight: bold;
  width: 100%;
}

select#order {
  padding-left: 35px !important;
}

.select-group {
  position: relative;
}

.select-group .icon {
  display: block;
  font-size: 14px;
  height: 14px;
  font-family: icomoon;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto 0;
  z-index: -1;
}

input[type="radio"] {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 24px;
  border: 3px solid transparent;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  appearance: none;
  -webkit-appearance: none;
}

input[type="radio"]:hover {
  box-shadow: 0px 0px 0px 1px var(--primary-color);
  border: 0;
}

input[type="radio"]:checked {
  border: 3px solid var(--white-color);
  background-color: var(--primary-color);
}

input:not([type="range"]):hover,
select:hover,
input:not([type="range"]):focus,
select:focus {
  border: 1px solid var(--primary-color);
}

.container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 20px;
}

.container.setup {
  max-width: 1200px;
}

.container.large {
  max-width: 1640px;
}

.container.bg-large {
  max-width: 1780px;
}

.light-bg {
  background-color: var(--light-bg-color);
}

.w600 {
  max-width: 600px;
  margin: 0 auto;
}

.w800 {
  max-width: 800px;
  margin: 0 auto;
}

.w900 {
  max-width: 900px;
  margin: 0 auto;
}

.w1100 {
  max-width: 1100px;
  margin: 0 auto;
}

.lt-pd {
  padding: 10px 20px;
}

.sm-pd {
  padding: 20px;
}

.md-pd {
  padding: 40px 20px;
}

.bg-pd {
  padding: 80px 20px;
}

.block {
  display: block;
}

.grid {
  display: grid;
}

.lt-gap {
  grid-gap: 10px;
}

.sm-gap {
  grid-gap: 20px;
}

.md-gap {
  grid-gap: 40px;
}

.id-gap {
  grid-gap: 50px;
}

.bg-gap {
  grid-gap: 80px;
}

.id-cgap {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.col-12 {
  grid-template-columns: repeat(12, 1fr);
}

.col-11 {
  grid-template-columns: repeat(11, 1fr);
}

.col-10 {
  grid-template-columns: repeat(10, 1fr);
}

.col-9 {
  grid-template-columns: repeat(9, 1fr);
}

.col-8 {
  grid-template-columns: repeat(8, 1fr);
}

.col-7 {
  grid-template-columns: repeat(7, 1fr);
}

.col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-1 {
  grid-template-columns: repeat(1, 1fr);
}

.span-12 {
  grid-column: span 12;
}

.span-10 {
  grid-column: span 10;
}

.span-9 {
  grid-column: span 9;
}

.span-8 {
  grid-column: span 8;
}

.span-7 {
  grid-column: span 7;
}

.span-6 {
  grid-column: span 6;
}

.span-5 {
  grid-column: span 5;
}

.span-4 {
  grid-column: span 4;
}

.span-3 {
  grid-column: span 3;
}

.span-2 {
  grid-column: span 2;
}

.ai-gc {
  align-items: center;
}

.ai-ge {
  align-items: end;
}

.ai-gss {
  align-items: start;
}

.ai-gs {
  align-items: stretch;
}

.as-ge {
  align-self: end;
}

.js-ge {
  justify-self: end;
}

.ji-gc {
  justify-items: center;
}

.ji-ge {
  justify-items: end;
}

.ji-gss {
  justify-items: start;
}

.flex {
  display: flex;
}

.fw-w {
  flex-wrap: wrap;
}

.fd-fc {
  flex-direction: column;
}

.ai-fc {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.jc-sb {
  justify-content: space-between;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-fc {
  justify-content: center;
}

.jc-fe {
  justify-content: flex-end;
}

.no-shr {
  flex-shrink: 0;
}

.va-m {
  vertical-align: middle;
}

.desktop-none {
  display: none;
}

@media screen and (max-width: 1024px) {

  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3 {
    grid-template-columns: 1fr 1fr;
  }

  .span-12,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6,
  .span-5,
  .span-4,
  .span-3 {
    grid-column: span 2;
  }

  .tablet-none {
    display: none;
  }

  .desktop-none {
    display: block;
  }
}

@media screen and (max-width: 768px) {

  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2 {
    grid-template-columns: 1fr;
  }

  .span-12,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6,
  .span-5,
  .span-4,
  .span-3,
  .span-2 {
    grid-column: span 1;
  }

  .mobile-none {
    display: none;
  }

  .desktop-none {
    display: block;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-60 {
  padding-right: 60px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.ph-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.relative {
  position: relative;
}

.sm-text {
  font-size: 0.8em;
}

.mid-text {
  font-size: 1.3em;
}

.big-text {
  font-size: 1.6em;
}

.gg-text {
  font-size: 2em;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-black {
  color: var(--black-color) !important;
}

.text-white {
  color: var(--white-color);
}

.underline {
  position: relative;
}

.underline::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 24px;
  background-color: var(--primary-color);
}

.price-big {
  font-size: 5em;
  line-height: 0.8em;
  font-weight: 800;
}

@media screen and (max-width: 1024px) {
  .text-center-mobile {
    text-align: center;
  }

  .jc-fc-mobile {
    justify-content: center;
  }
}

.content-item {
  padding: 25px;
  border-radius: 24px;
  border: 1px solid var(--primary-color);
  transition: var(--transition);
  background-color: var(--white-color);
}

.content-item-gtr {
  grid-template-rows: 75px 62px auto;
}

.content-item.gray {
  border: 1px solid var(--border-color-o3);
}

.content-item.v-pd {
  padding: 40px 20px;
}

.content-item.no-pd {
  padding: 0;
}

.os {
  overflow: scroll;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

/* Buttons */

.btn {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 24px;
  font-size: 1em;
  transition: var(--transition);
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .btn {
    padding: 15px;
  }
}

.btn.mid {
  padding: 15px 20px;
}

.btn.big {
  font-size: 1.2em;
  padding: 18px 40px;
}

.btn.full {
  display: inline-block;
  width: 100%;
  text-align: center;
}


.btn.bordered {
  background-color: var(--primary-color);
  color: var(--black-color);
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn.bordered.gray {
  border: 1px solid var(--border-color-o3);
  color: var(--black-color);
}

.btn.bordered:hover,
.btn.bordered.active {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btn.bordered.light-hover:hover,
.btn.bordered.light-hover.active,
.btn.bordered.gray.light-hover:hover,
.btn.bordered.gray.light-hover.active {
  border: 1px solid var(--primary-color);
  background-color: var(--light-hover-color);
  color: var(--primary-color);
}

.btn.gray:hover {
  background-color: var(--border-color);
  color: var(--white-color);
}

.btn.action {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btn.action:hover,
.btn.action.active {
  // background-color: var(--secondary-color);
  color: var(--white-color) !important;
}

.btn.action.dark {
  background-color: var(--primary-color);
}

.btn.action.dark:hover,
.btn.action.dark.active {
  background-color: var(--primary-color);
}

.btn.wish {
  border: 1px solid var(--wish-color);
  color: var(--wish-color);
}

.btn.wish .btn-t {
  width: 92px;
}

.btn.wish:hover,
.btn.wish.active {
  background-color: var(--wish-color);
  color: var(--white-color);
}

.btn.wish:hover i::before,
.btn.wish.active i::before,
.btn.share:hover i::before,
.btn.share.active i::before,
.btn.bordered:hover i::before,
.btn.bordered.active i::before {
  color: var(--white-color);
}

.btn.favorite {
  background-color: var(--wish-color) !important;
  color: var(--white-color) !important;
}

.btn.share {
  border: 1px solid var(--info-color);
  color: var(--info-color);
}

.btn.share:hover,
.btn.share.active {
  background-color: var(--info-color);
  color: var(--white-color);
}

.btn.white {
  background-color: var(--white-color);
  color: var(--text-color);
  border: 1px solid var(--text-color-o3);
}

.btn.info:hover,
.btn.info.active {
  background-color: var(--tag-info-color);
}

.btn.info {
  background-color: var(--info-color);
  color: var(--white-color);
}

.btn.info:hover,
.btn.info.active {
  background-color: var(--tag-info-color);
  color: white;
}

.btn.border-gray {
  border: 1px solid var(--border-color-o3);
  color: var(--black-color);
}

.btn.border-grey {
  background-color: var(--border-color-o3);
}

.btn.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: initial;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.h-100 {
  height: 100%;
}

.h-90 {
  height: 90%;
}

.h-fa {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
}

/* Buttons */

/* Icons */

.icon {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-17 {
  font-size: 18px;
}

.i-18 {
  font-size: 18px;
}

.i-20 {
  font-size: 20px;
}

.i-24 {
  font-size: 24px;
}

.i-36 {
  font-size: 36px;
}

.i-37 {
  font-size: 37px;
}

.i-48 {
  font-size: 48px !important;
}

.i-60 {
  font-size: 60px;
}

/* Icons */

.danger-border {
  border-color: var(--danger-color) !important;
}

.error {
  color: var(--danger-color);
}

.loaderx {
  border: 8px solid #f3f3f3;
  border-radius: 100px;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Modal */
.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay {
  z-index: 9999;
  background: var(--text-color-o1) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Content {
  position: absolute;
  background-color: var(--white-color);
  border-radius: 24px;
  padding: 20px;
  border: 1px solid var(--color-cecece-o3);
  outline: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: auto;
  height: max-content;
  max-height: 100vh;
}

/* Modal */

/* Hide: Google Logo, terms of use, and Report a problem div */
a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.pointer {
  cursor: pointer;
}

.checkbox-group label input[type="checkbox"] {
  display: none;
}

.checkbox-group label span {
  display: inline-block;
  border: 1px solid var(--border-color-o3);
  border-radius: 24px;
  padding: 27px 30px;
  cursor: pointer;
  width: 100%;
  height: 80px;
  font: normal normal normal 14px/19px Open Sans;
}

.checkbox-group label:hover span {
  border: 1px solid var(--primary-color);
}

.checkbox-group label input[type="checkbox"]:checked~span {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.checkbox-group label span div {
  margin-top: 3px;
}

.checkbox-group label span .icon {
  color: var(--primary-color);
  font-size: 24px;
}

.checkbox-group label span .icon.icon-contact {
  margin-right: 24px;
}

.checkbox-group label span .check-disabled {
  color: var(--color-f8f8f8) !important;
}

@media screen and (max-width: 768px) {
  .checkbox-group {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {
  .checkbox-group label span {
    padding: 10px 14px;
  }
}

.radio-group label span {
  display: inline-block;
  border: 1px solid var(--border-color-o3);
  border-radius: 24px;
  padding: 15px 30px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  font: normal normal normal 14px/19px Open Sans;
}

.radio-group label:hover span {
  border: 1px solid var(--primary-color);
}

.radio-group label input[type="radio"] {
  display: none;
}

.radio-group label input[type="radio"]:checked~span {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: white;
}

.radio-group label span div {
  margin-top: 3px;
}

.radio-group label span .icon {
  color: var(--primary-color);
  font-size: 24px;
}

.radio-group label span .icon.icon-contact {
  margin-right: 24px;
}

.radio-group label span .check-disabled {
  color: var(--color-f8f8f8) !important;
}

@media screen and (max-width: 768px) {
  .radio-group {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {
  .radio-group label span {
    padding: 10px 14px;
  }
}