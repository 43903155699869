.cluster-marker {
    color: #fff;
    background: var(--primary-color);
    border-radius: 24px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .property-marker {
    background: none;
    border: none;
  }
  
  .property-marker img {
    width: 25px;
}