.modal-register-realtor {
    max-width: 100%;
    padding: 30px 80px 80px !important;
    height: 100% !important;
    border-radius: 0 !important;
    .register-realtor-header, .register-realtor-code-header {
        h1 {
            font: normal normal 800 30px/41px Open Sans;
        }

        h2 {
            font: normal normal 600 28px/38px Open Sans;
        }

        p {
            font: normal normal normal 18px/24px Open Sans;
            color: var(--text-color);
        }

        .sub-title {
            font: normal normal normal 16px/22px Open Sans;
            padding: 0 35px;
        }

        .back {
            cursor: pointer;
            position: absolute;
            left: 100px;
            top: 90px;
            font: normal normal normal 18px/24px Open Sans;
        }

        .logo {
            img {
                margin: 0 auto 50px;
            }
        }
    }

    select {
        height: 65px;
    }
}

@media screen and (max-width: 650px) {
    .modal-register-realtor {
        padding: 30px 20px 80px !important;
    }
    .back {
        left: 40px !important;
        top: 70px !important;
    }
}

.setup {
    h1 {
        font: normal normal bold 22px/30px Open Sans !important;
        width: 80%;
        margin: 80px auto 0;
    }

    .help {
        text-align: center;
        font: normal normal normal 18px/24px Open Sans;
        color: var(--secondary-color);
    }

    .btn.white,
    .btn.action {
        width: 212px;
        height: 68px;
        font: normal normal 800 1.375em/1.875em Open Sans !important;
    }

    .plans .btn.action {
        padding: 12px;
    }
}

.setup.integrations-setup {
    max-width: 1280px !important;
}

.img-back {
    margin-top: 70px;
    width: 100%;
    background: transparent url(../../../assets/img/mapa_pb.png) 0% 0% no-repeat padding-box;
    background-size: cover;
    position: absolute;
    height: 240px;
    left: 0;
}

.footer-buttons.acting {
    margin-top: 280px;
}

.footer-buttons.integrations {
    margin: 100px auto 0;
}

@media screen and (max-width: 1024px) {
    .footer-buttons {
        grid-template-columns: 1fr !important;
    }
}


/* Acting */
.idioma-label {
    justify-self: end;
    align-self: center;
}

.idioma-group {
    max-width: 320px;
}

.div-map {
    background-color: #343332;
    height: 114px;
    position: absolute;
    width: 100vw;
    left: 0;
    margin-top: 100px;
}

@media screen and (max-width: 768px) {
    .idioma-label {
        justify-self: center;
    }

    .idioma-group {
        max-width: unset;
    }
}

/* Acting */


/* Integrations */
.integrations-48 {
    width: 48% !important;
}
.integrations-items {
    position: relative;

    .integrations-item {
        background: var(--white-color) 0% 0% no-repeat padding-box;
        border: 1px solid var(--border-color-o3);
        border-radius: 24px;
        min-height: 150px;
        padding: 30px 25px;

        .icon {
            margin-top: 6px;
            color: var(--primary-color);
        }

        .content {
            .title {
                font: normal normal bold 18px/24px Open Sans;
                margin-bottom: 14px;
            }

            .description {
                font: normal normal normal 16px/22px Open Sans;
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 33px;
        }
        
        .switch input { 
            opacity: 0;
            width: 0;
            height: 0;
        }
        
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--color-efefef);
            -webkit-transition: .4s;
            transition: .4s;
        }
        
        .slider:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 29px;
            left: 2px;
            bottom: 2px;
            background-color: var(--white-color);
            transition: .4s;
        }
        
        input:checked + .slider {
            background-color: var(--primary-color);
        }
        
        input:focus + .slider {
            box-shadow: 0 0 1px var(--primary-color);
        }
        
        input:checked + .slider:before {
            -webkit-transform: translateX(28px);
            -ms-transform: translateX(28px);
            transform: translateX(28px);
        }
        
        .slider.round {
            border-radius: 24px;
        }
        
        .slider.round:before {
            border-radius: 24px;
        }
          
    }
}

/* Integrations */


/* Notifications */
.type-contact label input[type="checkbox"] {
    display: none;
}

.type-contact label span {
    display: inline-block;
    border: 1px solid var(--border-color-o3);
    border-radius: 24px;
    padding: 27px 30px;
    cursor: pointer;
    width: 100%;
    height: 80px;
    font: normal normal normal 14px/19px Open Sans;
}

.type-contact label:hover span {
    border: 1px solid var(--secondary-color);
}

.type-contact label input[type="checkbox"]:checked~span {
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
}

.type-contact label span div {
    margin-top: 3px;
}

.type-contact label span .icon {
    color: var(--primary-color);
    font-size: 24px;
}

.type-contact label span .icon.icon-contact {
    margin-right: 24px;
}

.type-contact label span .check-disabled {
    color: var(--color-f8f8f8) !important;
}

@media screen and (max-width: 768px) {
    .type-contact {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .type-contact label span {
        padding: 10px 14px;
    }
}

.type-contact {
    position: relative;
    width: 100%;
    max-width: 366px;
    margin: auto;
    grid-gap: 30px;
    label {
        background: transparent;
        span {
            background: white;
        }
    }
}
/* Notifications */