footer {
    background-color: var(--text-color);
}

footer, footer p, footer li a, footer li span {
    color: var(--white-color) !important;
    font: normal normal normal 16px/22px Open Sans !important;
    line-height: 1.6em !important;
}

footer li {
    padding: 0 !important;
}

footer>.container {
    .title-green {
        color: var(--primary-color);
        font-size: 18px;
        font-weight: 600;
    }
}

footer .social-icons .a-icon {
    color: var(--primary-color);
    transition: var(--transition);
}

footer .social-icons .a-icon:hover {
    color: var(--white-color);
}

.div-privacity {
    padding-right:5%;
}

@media screen and (max-width: 768px) {
    .div-privacity {
        text-align: center !important;
    }
}