.modal-register-realtor {
    .info-code {
        font: normal normal 300 16px/22px Open Sans;
        text-align: center;
        margin: -40px auto 50px;
        color: darkred;
    }

    .info-phone {
        font: normal normal 300 16px/22px Open Sans;
        text-align: center;
        margin: 0 auto 50px;
    }

    .btn {
        font: normal normal 800 13px/18px Open Sans !important;
        justify-self: center;
    }
    .disabled {
        pointer-events: none;
        cursor: initial;
    }
}
.container.small {
    max-width: 600px;
}