.modal-scheduling {
    .scheduling-header .sub-title {
        padding: 0 115px !important;
    }

    .scheduling-footer a {
        font: normal normal 800 13px/18px Open Sans;
    }
}

@media screen and (max-width: 768px) {
    .modal-scheduling {
        .scheduling-header .sub-title {
            padding: 10px !important;
        }

        .scheduling-header .text-center .mb-80{
            margin-bottom: 30px !important;
        }

        .scheduling-footer {
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }

}