.modal-scheduling {
    max-width: 719px;
    padding: 30px 80px 80px !important;

    .scheduling-header {
        h1 {
            font: normal normal 800 30px/41px Open Sans;
        }
    
        .sub-title {
            font: normal normal normal 16px/22px Open Sans;
            padding: 0 35px;
        }

        a {
            position: absolute;
            left: 45px;
            top: 42px;
        }
    }
}

.p-ms {
    padding: 30px 20px !important;
}

@media screen and (max-width: 768px) {
    .modal-scheduling {
        padding-left: 10px !important;
        padding-right: 10px !important;
    
        .login .login-header h1 {
            padding-left: 50px !important;
            padding-right: 50px !important;  
            }

    }

}