.container-details {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-cecece-o3);
    border: 1px solid --border-color-2;
    z-index: 9991;
}

.btn-fechar {
    width: 100%;
    color: #00A651;
}

.details-property-slider {
    overflow: hidden;
    width: 1040px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px;

    .property-image {
      background-position: top;
      background-size: contain;
      width: 1000px !important;
      background-repeat: no-repeat;
      height: 600px;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      color: var(--white-color);
    }
  }

  @media screen and (max-width: 840px) {
    .details-property-slider {
        width: 100%;
        padding: 0;
        .property-image {
            width: 100vw !important;
            height: 100vw !important;
        }
    }
  }

  @media screen and (max-height: 870px) {
    .details-property-slider {
        height: calc(100% - 20px);
        .property-image {
            width: calc(100vh - 20px);
            height: calc(100vh - 20px);
        }
    }

    @media screen and (max-height: 768px) {
      .btn-fechar {
        background: #00A651;
        color: white;
      }

      .on-mobile{
        padding-right: 35%;
        padding-left: 35%;
	      padding-bottom: 5%;
      }
    }

  }

