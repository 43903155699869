#main {
    position: relative;
    top: 64px;
}

.circle-numbered {
    flex: 0 0 150px;
    border: 1px solid var(--primary-color);
    font-size: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    width: 150px;
    height: 150px;
    border-radius: 24px;
}

.circle-numbered.white {
    background-color: var(--white-color);
}

.circle-numbered.small {
    font-size: 2em;
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
}

@media screen and (max-width: 1024px) {
    #parceria .how-work-cont {
        grid-template-columns: 1fr;
    }

    #parceria .how-work-cont .how-work-item {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    #parceria .how-work-cont .how-work-item .circle-numbered {
        margin-right: 0;
        margin-bottom: 40px;
    }
}

/* Como funciona */

/* Tecnologias */

#tecnologias .relative::before {
    content: "";
    display: block;
    width: 100%;
    height: 35%;
    background-color: var(--primary-color);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
}

.control .img-cont img {
    max-width: 80%;
    margin: 0 auto;
    margin-top: -70px;
    margin-bottom: -100px;
}

@media screen and (max-width: 1024px) {
    .control .img-cont img {
        margin-bottom: 40px;
    }
}

/* Tecnologias */

/* Tabela de planos */

#table-price {
    /* padding-top: 80px; */
    padding-top: 120px;
    background: url("../../assets/img/bg-tabela-preco.png") center top no-repeat;
    /* background-size: contain; */
    background-size: cover;
}

@media screen and (max-width: 1300px) {
    #table-price {
        /* background-size: auto 30%; */
    }
}

.list-plans ul li {
    text-align: start;
}

.list-plans ul li::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--primary-color);
    border-radius: 24px;
    margin-right: 10px;
}

.list-plans .swiper-pagination {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}

.list-plans .swiper-pagination .swiper-pagination-bullet {
    margin: 0 5px;
    width: 10px;
    height: 10px;
}

.list-plans .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--primary-color);
}

/* Tabela de planos */

/*.w-plan {*/
/*    width: 340px;*/
/*    margin-right: 140px;*/
/*}*/

.bg-city {
    background: url("../../assets/img/green-city.png") bottom no-repeat;
    margin-bottom: -41px;
}

.icon-map {
    width: 65px;
    height: 44px;
    color: var(--primary-color);
}
