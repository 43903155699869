#main {
    position: relative;
    top: 64px;
}


@media screen and (max-width: 1024px) {
    .text-center-mobile {
        text-align: center;
    }
    .jc-fc-mobile {
        justify-content: center;
    }
}

/* Quero anunciar */

.get-contact form {
    max-width: 550px;
    width: 100%;
    padding: 60px 40px;
}

.get-contact form .bg-form {
    position: absolute;
    left: -30%;
    top: 5%;
    height: 90%;
    z-index: -1;
}

.new-property .img-cont img {
    margin: 0 auto;
    margin-top: -140px;
    margin-bottom: -140px;
}

.step-list li {
    position: relative;
}

.step-list li .circle-numbered {
    position: relative;
}

.step-list li:first-of-type {
    padding-top: 0;
}

.step-list li:last-of-type {
    padding-bottom: 0;
}

.step-list li:not(:last-of-type)::before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    height: 100%;
    left: 40px;
    background-color: var(--primary-color);
}

.step-list li:first-of-type::before {
    top: 70px;
}

.bg-step {
    background: url('../../assets/img/como-funciona.png') center center no-repeat;
    background-size: cover;
    border-radius: 24px;
    border: 1px solid var(--border-color-o3);
}

@media screen and (max-width: 1024px) {
    .new-property .img-cont img {
        margin-top: -60px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 768px) {
    .get-contact form .bg-form {
        display: none;
    }

    .bg-step {
        display: none;
    }
}

.color-primary {
    color: var(--primary-color);
}

.react-tel-input .selected-flag,
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before
{
    border-radius: 16px 0 0 16px;
}

.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
    border-color: #27df4c;
    box-shadow: 0 0 0 0.2rem #27df4c40;
}

.react-tel-input .form-control {
    width: 100%;
    height: 65px;
    border: 1px solid var(--border-color-o3);
    border-radius: 24px;
    padding: 0 20px 0 60px;
    transition: var(--transition);
}

.react-tel-input .country-list {
    /*width: 120px;*/
}

.country-name {
    /*display: none;*/
}

.react-tel-input .form-control:focus {
    border: 1px solid var(--primary-color);
    background-color: inherit;
    outline: 0;
    box-shadow: inherit;
}

.react-tel-input .country-list .search {
    padding: 10px;
}

.react-tel-input .country-list .search-box {
    margin: 0;
    height: 40px
}