:root {
    --white-color: #FFFFFF;
    --black-color: #1e1e1e;
    --secondary-color: #80ff00;
    --border-color: #707070;
    --border-color-o3: #7070704d;
    --color-a9acb1:#a9acb1;
    --text-color: #09230E;
    --text-color-o1: #09230e1f;
    --text-color-o2: #09230e33;
    --text-color-o3: #09230E4D;
    --color-4ba543: #4BA543;
    --color-43a047: #43A047;
    --color-fece3f: #FECE3F;
    --color-2387eb: #2387EB;
    --color-207bd6: #207BD6;
    --color-efefef: #EFEFEF;
    --color-dcdcdc: #DCDCDC;
    --primary-color: #80ff00;
    --primary-color-o3: #66cc00;
    --primary-color-o5: #27DF4C80;
    --color-cecece: #CECECE;
    --color-cecece-o3: #7070704d;
    --color-b5b5b5: #B5B5B5;
    --color-f8f8f8: #F8F8F8;
    --color-878787: #878787;
    --tag-info-color: #3ED7F6;
    --color-c7c7c7: #C7C7C7;
    --light-hover-color: #A1FFB4;
    --color-dbdbdb: #DBDBDB;
    --light-bg-color: #F6F6F6;
    --lightg-bg-color: #E9EBE9;
    --tag-sell-color: #80ff00;
    --color-d9d9d9: #D9D9D9;
    --color-f65f88: #F65F88;
    --wish-color: #E959A1;
    --info-color: #2A8DFF;
    --color-afbbb4: #afbbb4;
    --danger-color: #F61700;
    --transition: all 0.2s ease-in-out 0s;
    --transition-fast: all 0.1s ease-in-out 0s;
}
    