.grid-search {
  min-height: calc(100vh - 68px);
}

.search-properties .btn {
  position: absolute;
  top: 7px;
  right: 7px;
}

.property-results {
  max-height: calc(100vh - 68px);
  overflow-y: auto;
}

.map-search {
  overflow: hidden;
}

.map-search .map-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.map-search .map-full .close-map {
  position: absolute;
  z-index: 8;
  background-color: var(--primary-color);
  color: var(--black-color);
  top: 8px;
  right: 65px;
}

.property-filter-options {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 9;
  background-color: var(--white-color);
  padding: 0;
  transition: var(--transition);
}

.property-filter-options .property-filter-content {
  border: 1px solid var(--border-color-o3);
  border-radius: 25px;
  margin-top: 40px;
}

.property-filter-options .property-filter-content .close-filter-options {
  font-size: 18px;
}

.property-filter-options.active {
  left: 0;
}

.property-filter-options form {
  height: calc(100vh - 174px);
  overflow: auto;
}

.property-filter-options .property-deal-type>label input {
  display: none;
}

.property-filter-options .property-deal-type>label {
  flex: 0 0 50%;
  text-align: center;
  padding: 0;
  cursor: pointer;
}

.property-filter-options .property-deal-type label span {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid var(--border-color-o3);
  position: relative;
}

.property-filter-options .property-deal-type input:checked~span {
  color: var(--primary-color);
}

.property-filter-options .property-deal-type input:checked~span::before {
  display: block;
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 5px;
  background-color: var(--primary-color);
  z-index: 2;
}

.property-filter-options .property-value-ruler input {
  display: none;
}

.property-filter-options .property-value-ruler input:checked~span {
  border: 1px solid var(--primary-color);
  background-color: var(--light-hover-color);
  color: var(--secondary-color);
}

.property-filter-options .property-value-ruler .value-text {
  font: normal normal 300 16px/22px Open Sans;
  width: 256px;
  margin-left: 70px;
}

.property-filter-options .property-min-max-value .input-group .rs {
  position: absolute;
  top: 26px;
  left: 20px;
  font: normal normal 800 15px/20px Open Sans;
}

.budget-value .input-range__label--value,
.budget-value .input-range__label--min,
.budget-value .input-range__label--max {
  display: none;
}

.budget-value .input-range__track {
  height: 10px;
  background-color: var(--color-a9acb1);
}

.budget-value .input-range__track--active {
  background-color: var(--primary-color);
}

.budget-value .input-range__slider {
  background-color: var(--white-color);
  box-shadow: unset;
  top: -15px;
  height: 43px;
  width: 43px;
  margin-left: -1.5rem;
  border: 1px solid var(--primary-color-o5);
}

.budget-value .input-range__slider:after {
  display: block;
  content: "||";
  width: 43px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding: 7.5px 0px;
  color: var(--primary-color);
}

.budget-value .input-range__slider:active {
  transform: none;
}

.property-filter-options .filter-actions {
  border-top: 1px solid var(--border-color-o3);
}

.property-thumb-slider {
  height: auto !important;
}

.grid-footer-filters {
  border-top: 1px solid var(--border-color-o3);
}

@media screen and (max-width: 1024px) {
  .property-with-map .property-item .property-content .grid-name-price {
    grid-template-columns: repeat(2, 1fr);
  }

  .map-search .map-full {
    display: none;
  }

  .property-results {
    max-height: unset;
  }

  .property-filter-options {
    position: fixed;
    top: 50px;
  }

  .grid-footer-filters {
    grid-template-columns: 1fr !important;
  }

}

@media screen and (max-width: 768px) {
  .property-filter-options .filter-actions span {
    display: none;
  }

  .property-filter-content {
    margin-top:16px !important;
    border-radius: 0px !important;
  }
}