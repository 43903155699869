.modal-login {
    max-width: 719px;
    padding: 30px 80px 80px !important;
}

@media screen and (max-width: 768px) {

    .modal-login {
        padding-left: 10px !important;
        padding-right: 10px !important;
    
        .h1{
            padding-left: 50px !important;
            padding-right: 50px !important;  
            }

    }
}