.property-item {
    border: 1px solid var(--border-color-o3);
    border-radius: 24px;
    overflow: hidden;
    width: auto !important;
}

.property-item .property-image {
    min-height: 140px;
}

.property-item .property-content {
    padding: 20px 15px;
    background-color: var(--white-color);
}

.property-item .property-content h6{
    display: flex;
    align-items: center;
    height: 42px;
}

.property-item .gray-content {
    color: var(--border-color);
}

.property-tag, a.property-tag {
    padding: 5px 10px;
    border-radius: 24px;
    color: var(--white-color);
}

body .property-tag:hover {
    color: var(--white-color);
}

.property-tag.tag {
    padding: 5px 20px;
    font: normal normal normal 14px/19px Open Sans;
}
.property-tag.sale-rent {
    background-color: var(--tag-sell-color);
    font: normal normal normal 14px/19px Open Sans;
}

.property-tag.sell {
    background-color: var(--primary-color);
}

.property-thumb-slider {
    height: 360px !important;
}

.property-thumb-slider .property-tag.new {
    color: var(--white-color);
}

.property-thumb-slider .swiper-pagination {
    text-align: left;
    color: var(--white-color);
    font-weight: 700;
    background: #09230e70 0% 0% no-repeat padding-box;
    border-radius: 24px;
    padding: 7px 20px;
    width: auto;
    margin-left: 15px;
}

.property-thumb-slider .swiper-button-next::after, .property-thumb-slider .swiper-button-prev::after {
    color: var(--white-color);
    font-size: 18px;
}

.property-thumb-slider .property-wish {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 24px;
    background-color: var(--white-color);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    z-index: 1;
}
.property-item .swiper-container {
    height: 200px;
}

.property-item.none-fraction {
    .swiper-pagination {
        display: none;
    }
}

.price {
    color: var(--primary-color-o3);
    font-weight: 800;
    display: flex;
    align-items: center;
    padding-left: 15px;
    justify-self: end;
}

.price small {
    font-size: 0.7em;
    margin-right: 5px;
}

@media screen and (max-width: 1370px) {
    .property-with-map .property-item .property-content .grid-name-price{
        grid-template-columns: 1fr;
    }
}