.modal-share {
    max-width: 1120px;
    padding: 30px 80px 50px !important;

    h1 {
        font: normal normal 800 30px/41px Open Sans;
    }

    span {
        font: normal normal normal 16px/22px Open Sans;
    }

    a {
        font: normal normal 800 13px/18px Open Sans;
    }
      
    .share-button {
        border: 1px solid var(--border-color-o3);
        border-radius: 24px;
        padding: 27px 30px;
        cursor: pointer;
        width: 100%;
        height: 80px;
        font: normal normal normal 14px/19px Open Sans;

        .icon {
            color: var(--primary-color);
            font-size: 24px;
            margin-right: 24px;
        }
    }

    .share-button:hover {
        border: 1px solid var(--secondary-color);
    }    
      
}

@media screen and (max-width: 768px) {

    .modal-share {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}