.container-cookies.hide {
    display: none !important;
}

.box-cookies {
    position: absolute;
    background: var(--white-color);
    bottom: 30px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    left: 10px;
    right: 10px;
    padding: 30px 50px;
}

.box-cookies .msg-cookies {
    color: var(--text-color);
    font: normal normal normal 16px/22px Open Sans;
    margin-right: 78px;
}

.box-cookies .btn-cookies-cancel {
    background-color: var(--color-f65f88);
}

.box-cookies .btn-cookies-accept {
    background-color: var(--primary-color);
}

.box-cookies .btn-cookies {
    cursor: pointer;
    align-self: normal;
    font: normal normal 800 0.813em/18px Open Sans;
    border-radius: 24px;
    height: 2.500rem;
    margin-left: 42px;
    padding: 0 63px;
    color: var(--white-color);
    border: initial;
}

@media screen and (max-width: 800px) {
    .box-cookies {
        flex-direction: column;
    }
    .box-cookies .btn-cookies {
        margin: 10px 5px;
    }
}

@media screen and (max-width: 480px) {
    .box-cookies {
        div {
           display: block;
            width: 100%;
        }
        .btn-cookies {
            width: 100%;
            margin: 0;
            margin-top: 10px !important;
        }
    }
}

.container-cookies {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(5, 42, 23, 0.32) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-cecece-o3);
    z-index: 9991;
}

