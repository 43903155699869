.offer {
  .title {
    color: var(--text-color);
    font: normal normal normal 1.125em/1.500rem Open Sans;
  }
  h5 {
    color: var(--text-color);
    font: normal normal 600 1.750em/2.375rem Open Sans;
  }
  h6 {
    color: var(--text-color);
    font: normal normal 600 1.125em/1.500rem Open Sans;
  }
  p {
    color: var(--text-color);
    font: normal normal normal 1.000em/1.375rem Open Sans;
  }
  li {
    font: normal normal normal 1.000em/1.375rem Open Sans;
    color: var(--text-color);
    padding-bottom: 7.5px;
    .icon {
      width: 14px;
      height: 12px;
      color: var(--primary-color);
    }
  }
  .description {
    .price {
      font: normal normal bold 2.313em/3.188rem Open Sans;
      color: var(--text-color);
    }
  }
  .offer-item {
    overflow: hidden;
    .property-image {
      min-height: 290px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .property-image:first-child{
      margin-left: 0px;
    }

    .property-image:last-child{
      margin-right: 0px;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      color: var(--white-color);
    }
  }
  .property-thumb-slider {
    height: auto !important;
  }
  .offer-broker {
    border-radius: 24px;
    border: 1px solid rgba(112, 112, 112, .3);
    max-width: 400px;
    padding: 20px;
    height: min-content;
    img {
      width: 57px;
      height: 57px;
      border-radius: 24px;
    }
    h5 {
      font: normal normal normal 1em/1.375rem Open Sans;
      margin-bottom: 7px;
    }
    h6 {
      font: normal normal 300 1em/1.375rem Open Sans;
    }
  }
  .offer-values {
    min-height: 160px;
    .label {
      font: normal normal 600 1em/1.375rem Open Sans;
    }
    .value {
      font: normal normal bold 1.125em/1.500rem Open Sans;
      text-align: end;
      font-size: 14px;
    }
    small {
      text-align: left;
      font: normal normal bold 0.813em/1.125rem Open Sans;
      font-size: 14px;
    }
  }
  .a-center{
    text-align: center;
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {
  .offer-values .col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

.offer-item {
    .property-image {
      margin-left: 0px!important;
      margin-right: 0px!important;
    }
}

}

.icons-offer {
  li .icon {
    width: 21px;
    height: 17px;
    margin-bottom: 3px;
  }
}