.home {
    background-color: #fff;
    position: relative;
}

.home .a-for-more {
    line-height: 1.6em;
}
.filter-form .content-item>div:not(:last-of-type) {
    border-right: 1px solid var(--border-color-o3);
}

.filter-form .buy-rent input[type="radio"] {
    display: none;
}

.filter-form .buy-rent input[type="radio"]~span {
    color: var(--border-color);
    font-size: 1.4em;
    font-weight: 800;
    cursor: pointer;
    margin: 0 20px;
}

.filter-form .buy-rent input[type="radio"]:checked~span {
    color: var(--text-color);
}

.room-qnty label input[type="checkbox"] {
    display: none;
}

.room-qnty label span {
    display: inline-block;
    border: 1px solid var(--border-color-o3);
    border-radius: 24px;
    padding: 10px 20px;
    cursor: pointer;
}

.room-qnty label:hover span {
    border: 1px solid var(--primary-color);
}

.room-qnty label input[type="checkbox"]:checked~span {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: white;
}

.filter-form .property_value {
    height: 40px;
    border: unset;
    font-weight: bold !important;
    border: 1px solid var(--white-color);
    padding: 0 15px;
}

@media screen and (max-width: 768px) {
    .filter-form .content-item>div:not(:last-of-type) {
        border-right: unset;
    }

    .room-qnty {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .content-item {
        padding: 25px !important;
    }
    .room-qnty label span {
        padding: 10px 14px;
    }
}

.garages-qnty label input[type="checkbox"] {
    display: none;
}

.garages-qnty label span {
    display: inline-block;
    border: 1px solid var(--border-color-o3);
    border-radius: 24px;
    padding: 10px 20px;
    cursor: pointer;
}

.garages-qnty label:hover span {
    border: 1px solid var(--primary-color);
}

.garages-qnty label input[type="checkbox"]:checked~span {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: white;
}

.filter-form .property_value {
    height: 40px;
    border: unset;
    font-weight: bold !important;
    border: 1px solid var(--white-color);
    padding: 0 15px;
}

@media screen and (max-width: 768px) {
    .filter-form .content-item>div:not(:last-of-type) {
        border-right: unset;
    }

    .garages-qnty {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .content-item {
        padding: 25px !important;
    }
    .garages-qnty label span {
        padding: 10px 14px;
    }
}

#main-banner{
    background: transparent url(../../assets/img/white-city.png) bottom no-repeat padding-box; 
}

#home-properties .property-slider {
    transform: translateY(-20px);
}

#home-properties .property-slider .property-item{
    margin-right: 8px;
}

#home-properties .img-back {
    margin-top: 9px;
    width: 100%;
    height: 112px;
    background: none;
    background-size: cover;
    position: absolute;
}

.prop-home-pag {
    left: 0;
    right: 0;
}

.prop-home-pag span {
    width: 10px;
    height: 10px;
}

.prop-home-pag .swiper-pagination-bullet-active {
    background-color: var(--primary-color);
}

.prop-home-pag span:not(:last-of-type) {
    margin-right: 10px;
}

input#budget {
    width: 100%;
}

.budget-cont .input-range__label--value,
.budget-cont .input-range__label--min,
.budget-cont .input-range__label--max {
    display: none;
}

.budget-cont .input-range__track {
    height: 10px;
    background-color: var(--color-a9acb1);
}

.budget-cont .input-range__track--active {
    background-color: var(--primary-color);
}

.budget-cont .input-range__slider {
    background-color: var(--primary-color);
    box-shadow: unset;
    top: -10px;
    height: 30px;
    width: 30px;
    border: none;
    margin-left: -1.5rem;
}

.budget-cont .input-range__slider:active {
    transform: none;
}

.budget-price {
    color: var(--primary-color);
    font-weight: 600;
}

.budget-price small {
    font-size: 0.7em;
}

#anuncie-imovel {
    background-color: var(--light-bg-color);
}

#anuncie-imovel .img-cont img {
    margin-top: -150px;
    margin-bottom: -120px;
}

#seja-corretor .parceiro-home {
    background: url('../../assets/img/green-city.png') bottom no-repeat;
    margin-bottom: -2px;
}

#seja-corretor .parceiro-home .icon {
    color: var(--primary-color);
}

#cities a {
    line-height: 20px;
}

@media screen and (max-width: 1024px) {
    #anuncie-imovel .img-cont img {
        margin: -80px auto 40px auto;
    }

    #anuncie-imovel.span-8>div:first-of-type {
        text-align: right;
    }

    #main-banner {
        background: none;
    }
}