.timepicker-component {
    .timepicker-slider {
        width: calc(100% - 120px);
    }
    .timepicker {
        background: var(--white-color) 0% 0% no-repeat padding-box;
        border: 1px solid var(--border-color-o3);
        border-radius: 24px;
        padding: 28px 30px;
        text-align: center;
        cursor: pointer;
        color: var(--text-color);
        &:hover {
            background-color: var(--secondary-color);
            color: var(--white-color);
        }
        .time {
            font: normal normal normal 16px/22px Open Sans;
        }
    }
    .timepicker.selected {
        background-color: var(--primary-color);
        color: var(--white-color);
    }
    .timepicker-next::after, .timepicker-prev::after {
        color: var(--text-color);
        font-weight: bold;
        font-size: 17px;
    }
}
