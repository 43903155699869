.modal-register-code-realtor {

    .btn {
        font: normal normal 800 13px/18px Open Sans !important;
    }
    .disabled {
        pointer-events: none;
        cursor: initial;
    }
}
.form-code {
    overflow: hidden;
    input {
        width: calc((100% - 120px) / 6) !important;
        margin: 10px;
        border-radius: 24px;
        height: 100px !important;
        text-align: center;
    }
}