header {
    background-color: var(--white-color);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 9990;
    transition: var(--transition);
}

header.shadow {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

body {
    padding-top: 68px;
}

header .header-actions ul li+li {
    margin-left: 40px;
}

header .header-actions .toggle-menu {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAWCAYAAACsR+4DAAAABHNCSVQICAgIfAhkiAAAAK1JREFUSEvt1MsNwjAQhOEZISEuoQUiKICUQilQAZSYVEBoJIOiiMcBKetosfZg3+P8Xlsf14fqtBKvIBoEWBL6gbpws9/eSdQBmj4JQjuG9SR2scLUcbpK3EAeI8RJeAzUmRFifjWUsNSbKRP7y8RyIvwG1nKS7NaNwMYMU2cKy4lwAdbyVIr8S6f0/Z3p8Xv8KHUPlzBPgJOAnTutO8BWYPOHGYGdC/ME+AXsE+dRQmvk2yg5AAAAAElFTkSuQmCC) center center no-repeat;
    background-size: contain;
    width: 30px;
    height: 20px;
}

.main-nav-social .a-icon {
    color: var(--primary-color);
}

.main-nav-social a:not(:last-of-type) {
    margin-right: 20px;
}

.main-navigation ul li a {
    display: block;
    padding: 15px 20px;
    font-weight: 700;
}

.main-navigation {
    background-color: var(--lightg-bg-color);
    position: fixed;
    top: 0;
    right: -600px;
    bottom: 0;
    max-width: 600px;
    width: 100%;
    transition: all .4s ease-in-out 0s;
}

.main-navigation.active {
    right: 0;
}

.main-navigation .top-menu {
    background: var(--white-color);
    padding-bottom: 8px;
}

.main-navigation .main-menu {
    background: var(--white-color);
    padding-top: 8px;
}

.main-navigation .footer-menu {
    background: var(--lightg-bg-color) 0% 0% no-repeat padding-box;
}

.main-navigation .close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
}

.main-navigation .li-login {
    display: none;
}

@media screen and (max-width: 768px) {
    body {
        padding-top: 50px;
    }

    .main-navigation .li-login {
        display: block;
    }
}