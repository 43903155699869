.modal-informations {
    max-width: 1120px;
    padding: 30px 80px 50px !important;

    h1 {
        font: normal normal 800 30px/41px Open Sans;
    }

    span {
        font: normal normal normal 16px/22px Open Sans;
    }

    a {
        font: normal normal 800 13px/18px Open Sans;
    }

    .group-question {
        min-height: 295px;
    }

    .info-type-contact label input[type="checkbox"] {
        display: none;
    }
    
    .info-type-contact label span {
        display: inline-block;
        border: 1px solid var(--border-color-o3);
        border-radius: 24px;
        padding: 27px 30px;
        cursor: pointer;
        width: 100%;
        height: 80px;
        font: normal normal normal 14px/19px Open Sans;
    }
    
    .info-type-contact label:hover span {
        border: 1px solid var(--secondary-color);
    }
    
    .info-type-contact label input[type="checkbox"]:checked~span {
        border: 1px solid var(--secondary-color);
        color: var(--secondary-color);
    }
    
    .info-type-contact label span div {
        margin-top: 3px;
    }

    .info-type-contact label span .icon {
        color: var(--primary-color);
        font-size: 24px;
    }
    
    .info-type-contact label span .icon.icon-contact {
        margin-right: 24px;
    }
    
    .info-type-contact label span .check-disabled {
        color: var(--color-f8f8f8) !important;
    }
    
    @media screen and (max-width: 768px) {
        .info-type-contact {
            flex-wrap: wrap;
        }
    }
    
    @media screen and (max-width: 500px) {
        .info-type-contact label span {
            padding: 10px 14px;
        }
    }
}

@media screen and (max-width: 768px) {
    .modal-informations {
        padding: 10px !important;
    }
    .modal-informations .group-question {
        min-height: 150px;
    
        #message{
            height: 150px!important;
        }
    }
   
}

