a.button {
  text-align: center;
  .icon {
    margin-right: 10px;
  }
}

.btn-t {
  height: 100%;
  display: flex;
  align-items: center;
}