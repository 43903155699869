@import "_colors";
@import "main";

body {
    width: 100%;
    padding: 68px 0 0;
    margin: 0px;
}

.root{
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.app{
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.stage {
    ul {
        font: normal normal 800 1.125em/1.500em Open Sans;
        color: #CECECE;
        li {
            padding: 0.625em 2.125em;
            align-items: center;
            display: flex;
        }
        .current {
            color: var(--text-color);
        }
        .completed {
            color: var(--primary-color);
        }
        .icon {
            background: #F8F8F8;
            padding: 8px;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            margin-right: 10px;
        }
    }
}

.pagination-container {
    text-align: center;
    width: fit-content;
    margin: 40px auto 50px;
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.1em;
        font-weight: bold;
    }
    .pagination > .active > a{
        color: #6D6D6D;
    }
    .pagination > li > a{
        padding: 5px 10px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        outline: none ;
    }
    .pagination > li > a, .pagination > li > span{
        color: var(--text-color);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset
    }
    .disabled {
        opacity: 0.5;
    }
}